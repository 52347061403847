import './App.css';
import Modal from './components/Modal';
import audioFile from './assets/anthem.mp3';
import React, { useState, useEffect} from 'react';
import gail1 from './assets/gail-1.png';
import gail2 from './assets/gail-2.jpg';
import gail3 from './assets/gail-3.jpg';
import gail4 from './assets/gail-4.jpeg';
import logo from './assets/gail-logo.png';
import revolugail from './assets/gail-queen.svg';
import gailHero from './assets/gail-hero.png';
import tucker from './assets/tucker.gif';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

   // Use useEffect to show the modal when the component mounts
   useEffect(() => {
    setModalVisible(true); // make true
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <nav class="bg-white-900" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <div class="max-w-7xl mx-auto px-4">
        <div class="flex items-center justify-between h-20">

          <div class="flex-shrink-0">
            <a href="https://twitter.com/gaillewiseth" target="_blank">
              <img
                class="h-14 w-14"
                src={logo}
                alt="gail city"
              />
            </a>
          </div>

          <div class="">
            <div class="ml-4 flex items-center md:ml-6">
              <a
                href="https://twitter.com/gaillewiseth"
                class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-sm font-bold"
                >Twitter</a
              >
              <a
                href="https://t.me/GailLewisEth"
                class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-sm font-bold"
                >Telegram</a
              >
              <a
                href="https://uniswap.org"
                class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-sm font-bold"
                >Buy</a
              >
            </div>
          </div>
        </div>
      </div>
    </nav>

    <section
      class="relative overflow-hidden h-12 bg-blue-300 flex items-center"
    >
      {
        modalVisible ? (
          <></>
        ) : (
          <>
            <div class="ticker-text absolute whitespace-nowrap">
            <h2 class="font-bold uppercase">
          Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
          Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
          Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
          Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis
          </h2>
          </div>
        </>
        )
      }
      
    </section>

    <section
      class="relative bg-cover bg-center bg-cover h-[48rem]"
      style={{"background-image": `url(${gailHero})`}}
    >
      <div class="absolute inset-0 bg-black opacity-10"></div>
      <div
        class="relative z-10 flex flex-col items-center justify-center h-full text-white"
      >

        <div
          class="flex justify-center items-center w-full h-full absolute inset-0"
        >
         {
            modalVisible ? (
              <></>
            ) : (
              <>
                <img
                  src={revolugail}
                  id='slidecaption'
                  alt="Centered SVG"
                  class="min-w-[200px] w-[600px]"
                />
              </>
            )
          }
        </div>
      </div>
    </section>

    <section
      class="relative overflow-hidden h-12 bg-blue-300 flex items-center"
    >
      {
        modalVisible ? (
          <></>
        ) : (
          <>
            <div class="ticker-text absolute whitespace-nowrap">
            <h2 class="font-bold uppercase">
            Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
            Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
            Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, 
            Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis, Gail Lewis
            </h2>
          </div>
        </>
        )
      }
    </section>

    <section class="bg-gray-900 py-12 px-8">
      <div
        class="py-6 bg-repeat bg-contain sam-sam-sam-sam"
        style={{"background-image": `url(${logo})`}}
      ></div>
      <div
        class="container mx-auto flex flex-col md:flex-row justify-between p-8"
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <div class="mr-10 flex flex-col justify-center">
        <h2 class="text-3xl text-white font-bold mb-6" style={{ paddingBottom: '15px' }}>
            <p>
              $GAIL $GAIL $GAIL
            </p>
          </h2>
          <h2 class="text-2xl text-white font-bold mb-6">
            <p>
              Prime Gail Lewis kept Walmart safe from all types of crime. Thank you for your service my goat.
            </p>
          </h2>
          <h2 class="text-2xl text-white font-bold mb-6">
           <p>
              We must honour her service, and fight against crime as she would have done.
            </p>
          </h2> 
          <h2 class="text-2xl text-white font-bold mb-6">
           <p>
              $GAIL is 1/1 tax, with 100% of taxes going directly to Gail Lewis.
            </p>
          </h2> 
        </div>

        <div class="">
        <img
            src={tucker}
            alt="Description here"
            class="w-full h-auto"
          />
        </div>
      </div>
      <div
        class="py-6 bg-repeat bg-contain sam-sam-sam-sam"
        style={{ "background-image": `url(${logo})` }}
      ></div>
    </section>

    <section class="text-center my-12">
      <h2 class="text-6xl text-blue-700 font-black mb-8" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        ALL HAIL PRIME GAIL
      </h2>
      <div class="flex justify-center py-12">
      <iframe
          width="325px"
          height="605px"
          src="https://www.tiktok.com/embed/7302056186474433822"
          title="TikTok video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        >
          </iframe>
      </div>
    </section>
    <section class="w-full">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 min-h-[700px]"
      >

        <div
          class="bg-contain bg-center h-64 lg:h-auto relative min-h-[700px]"
          style={{ "background-image": `url(${gail1})` }}
        ></div>

        <div
          class="bg-contain bg-center h-64 lg:h-auto relative min-h-[700px]"
          style={{ "background-image": `url(${gail2})` }}
        ></div>

        <div
          class="bg-contain bg-center h-64 lg:h-auto relative min-h-[700px]"
          style={{ "background-image": `url(${gail3})` }}
        ></div>


        <div
          class="bg-contain bg-center h-64 lg:h-auto relative min-h-[700px]"
          style={{ "background-image": `url(${gail4})` }}
        ></div>
      </div>
    </section>


    <section class="text-center my-12" style={{ paddingTop: '20px' }}>
      <h2 class="text-6xl font-black mb-8">
        ALL TAXES GO TO GAIL
      </h2>
    <div class="flex justify-center py-12">
      <ul>
        <li class="flex flex-row" style={{ marginBottom: '20px'}}>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginRight: '20px'}}/>
          <h2 class="text-4xl font-black mb-8" style={{ margin: 'auto'}}>1% BUY TAX</h2>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginLeft: '20px'}}/>
        </li>
        <li class="flex flex-row" style={{ marginBottom: '20px' }}>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginRight: '20px'}}/>
          <h2 class="text-4xl font-black mb-8" style={{ margin: 'auto'}}>1% SELL TAX</h2>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginLeft: '20px'}}/>
        </li>
        <li class="flex flex-row" style={{ marginBottom: '20px' }}>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginRight: '20px'}}/>
          <h2 class="text-4xl font-black mb-8" style={{ margin: 'auto'}}>GIVE BACK TO YOUR HEROES</h2>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginLeft: '20px'}}/>
        </li>
        <li class="flex flex-row" style={{ marginBottom: '20px' }}>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginRight: '20px'}}/>
          <h2 class="text-4xl font-black mb-8" style={{ margin: 'auto'}}>AND WE SHALL BE FREE</h2>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginLeft: '20px'}}/>
        </li>
        <li class="flex flex-row" style={{ marginBottom: '20px' }}>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginRight: '20px'}}/>
          <h2 class="text-4xl font-black mb-8" style={{ margin: 'auto'}}>THANK YOU $GAIL</h2>
          <img src={logo} style={{ margin: 'auto', width: '40px', marginLeft: '20px'}}/>
        </li>
      </ul>
      </div>
      
    </section>

    <footer class="bg-white-800 text-white py-8">
      <div
        class="container mx-auto flex flex-col md:flex-row justify-between items-center"
      >

        <div class="mb-4 md:mb-0">
          <img src={logo} alt="Logo" class="h-12" />
        </div>

        <div class="flex space-x-4">
          <a
            href="https://twitter.com/gaillewiseth"
            class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-med font-bold"
            >Twitter</a
          >
          <a
            href="https://t.me/GailLewisEth"
            class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-med font-bold"
            >Telegram</a
          >
          <a
            href="https://uniswap.org"
            class="text-blue-800 hover:text-blue-300 px-3 py-2 rounded-md text-med font-bold"
            >Buy</a
          >
        </div>
      </div>
    </footer>
    </div>
  );
}

export default App;
