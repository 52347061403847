import React from 'react';
import '../Modal.css';
import gail from '../assets/thanks-gail.png'

function Modal(props) {
  // If the prop 'show' is false, don't render anything
  if (!props.show) {
    return null;
  }

  return (
    <div className="modal" onClick={props.onClose}>
      <div className="modal-content">
        <img className='gailImg' src={gail} />
        <p className='text2'>In Honor of Gail Lewis</p>
        <p className='enter'>(click anywhere)</p>
      </div>
    </div>
  );
}

export default Modal;